import React, { FC, useCallback, useEffect, useRef } from "react";
import clsx from "clsx";
import { Namespace } from "types/namespace";
import { RootState } from "redux/types";
import { setPageNamespacesTableSettings } from "redux/actions/pageSettingsActions";
import { useDispatch, useSelector } from "react-redux";

// ag-grid
import localization from "components/agGrid/localization";
import { AgGridReact } from "ag-grid-react";
import { ColumnResizedEvent } from "ag-grid-community";
import DateRenderer from "components/agGrid/renderers/DateRenderer";
import NameRenderer from "components/agGrid/renderers/NamespaceNameRenderer";
import IsCanWriteRenderer from "components/agGrid/renderers/IsCanWriteRenderer";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100vh - 151px)",
  },
}));

const defaultColDef = {
  resizable: true,
  sortable: true,
  whiteSpace: "normal",
  cellClass: "centeredText",
};

const rowHeight = 40;

const columnDefs = [
  {
    headerName: "Идентификатор",
    field: "id",
    width: 100,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    sort: "desc",
  },
  {
    headerName: "Права",
    field: "isCanWrite",
    width: 100,
    cellRenderer: "IsCanWriteRenderer",
  },
  {
    headerName: "Имя пространства",
    field: "name",
    minWidth: 150,
    flex: 1,
    cellRenderer: "NameRenderer",
  },
  {
    headerName: "Комментарий",
    field: "comment",
    flex: 1,
  },
  {
    headerName: "Хранилище",
    field: "storageId",
  },
  {
    headerName: "Создатель",
    field: "ownerId",
  },
  {
    headerName: "Дата",
    field: "dateChanged",
    cellRenderer: "DateRenderer",
  },
];

interface Props {
  rowData: Namespace[];
  setGridApi: Function;
  setColumnApi: Function;
  onSelection: Function;
  onFirstDataRendered: any;
}

const DictionariesTable: FC<Props> = ({ rowData, setGridApi, setColumnApi, onSelection, onFirstDataRendered }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { namespaces: pageSettings } = useSelector((state: RootState) => state.pageSettings);
  const gridRef = useRef<any>(null);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
  };

  const onSelectionChanged = () => {
    onSelection();
  };

  const getRowNodeId = (data: Namespace) => String(data.id);

  const onColumnResized = useCallback(
    (params: ColumnResizedEvent) => {
      const { finished, columnApi, source } = params;
      if (source === "flex" || source === "api") return;
      if (finished) {
        const colState = columnApi.getColumnState();
        dispatch(setPageNamespacesTableSettings(colState));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!gridRef) return;
    if (!gridRef.current) return;
    if (pageSettings.table === undefined) return;
    gridRef.current.columnApi.setColumnState(pageSettings.table);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={clsx(classes.root, "ag-theme-balham")} style={{ fontSize: "14px" }}>
      <AgGridReact
        rowHeight={rowHeight}
        ref={gridRef}
        onFirstDataRendered={onFirstDataRendered}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        getRowNodeId={getRowNodeId}
        rowData={rowData}
        columnDefs={columnDefs}
        localeText={localization}
        rowSelection="multiple"
        enableRangeSelection
        onSelectionChanged={onSelectionChanged}
        onColumnResized={onColumnResized}
        suppressDragLeaveHidesColumns
        // suppressMovableColumns
        // suppressColumnMoveAnimation
        suppressCopyRowsToClipboard
        scrollbarWidth={8}
        frameworkComponents={{
          DateRenderer,
          NameRenderer,
          IsCanWriteRenderer,
        }}
      />
    </div>
  );
};

export default DictionariesTable;
