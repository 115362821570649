import React, { FC, useCallback } from "react";
import { Source } from "types";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/types";
import { setPageSourcesTableSettings } from "redux/actions/pageSettingsActions";

// ag-grid
import { ColumnResizedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import localization from "components/agGrid/localization";
import DateRenderer from "components/agGrid/renderers/DateRenderer";
import StatusRenderer from "components/agGrid/renderers/StatusRenderer";
import CheckboxRenderer from "components/agGrid/renderers/CheckboxRenderer";
import SourceNameRenderer from "components/agGrid/renderers/SourceNameRenderer";
import IsCanWriteRenderer from "components/agGrid/renderers/IsCanWriteRenderer";
import SourceTypeRenderer from "components/agGrid/renderers/SourceTypeRenderer";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100vh - 151px)",
  },
}));

const defaultColDef = {
  resizable: true,
  whiteSpace: "normal",
  cellClass: "centeredText",
};

const rowHeight = 50;

const columnDefs = [
  {
    headerName: "Идентификатор",
    field: "id",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    sort: "desc",
    width: 100,
  },
  {
    headerName: "Статус",
    field: "status",
    cellRenderer: "StatusRenderer",
    width: 100,
  },
  {
    headerName: "Права",
    field: "isCanWrite",
    width: 100,
    cellRenderer: "IsCanWriteRenderer",
  },
  {
    headerName: "Имя источника",
    field: "name",
    width: 320,
    cellRenderer: "SourceNameRenderer",
  },
  {
    headerName: "Тип",
    width: 220,
    field: "sourceDetail",
    valueGetter: (p: any) => p.data?.sourceDetail?.type ?? "",
    cellRenderer: "SourceTypeRenderer",
  },
  {
    headerName: "Сообщение",
    field: "message",
    width: 400,
  },
  {
    headerName: "Комментарий",
    field: "comment",
    flex: 1,
  },
  {
    headerName: "Создатель",
    field: "ownerId",
  },
  {
    headerName: "Активен",
    field: "isActive",
    cellRenderer: "CheckboxRenderer",
  },
  {
    headerName: "Дата изменения",
    field: "dateChanged",
    cellRenderer: "DateRenderer",
  },
];

interface Props {
  rowData: Source[];
  setGridApi: Function;
  setColumnApi: Function;
  onSelection: Function;
}

const SourcesTable: FC<Props> = ({ rowData, setGridApi, setColumnApi, onSelection }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { sources: pageSettings } = useSelector((state: RootState) => state.pageSettings);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
    if (pageSettings.table !== undefined) {
      params.columnApi.setColumnState(pageSettings.table);
    }
  };

  const onSelectionChanged = () => {
    onSelection();
  };

  const getRowNodeId = (data: Source) => String(data.id);

  const onColumnResized = useCallback(
    (params: ColumnResizedEvent) => {
      const { finished, columnApi, source } = params;
      if (source === "flex" || source === "api") return;
      if (finished) {
        const colState = columnApi.getColumnState();
        dispatch(setPageSourcesTableSettings(colState));
      }
    },
    [dispatch]
  );

  return (
    <div className={clsx(classes.root, "ag-theme-balham")} style={{ fontSize: "14px" }}>
      <AgGridReact
        rowHeight={rowHeight}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        getRowNodeId={getRowNodeId}
        rowData={rowData}
        columnDefs={columnDefs}
        rowSelection="multiple"
        localeText={localization}
        enableRangeSelection
        onSelectionChanged={onSelectionChanged}
        suppressDragLeaveHidesColumns
        onColumnResized={onColumnResized}
        // suppressMovableColumns
        // suppressColumnMoveAnimation
        suppressCopyRowsToClipboard
        scrollbarWidth={8}
        frameworkComponents={{
          DateRenderer,
          StatusRenderer,
          CheckboxRenderer,
          SourceNameRenderer,
          IsCanWriteRenderer,
          SourceTypeRenderer,
        }}
      />
    </div>
  );
};

export default SourcesTable;
